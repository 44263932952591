import "../styles/components/loading-spinner.css";
import PuffLoader from "react-spinners/PuffLoader";

export default function LoadingSpinner({ loading }: { loading: boolean }): JSX.Element | null {
  if (!loading) return null;
  return (
    <>
      <div className="loader-container">
        <PuffLoader color={"var(--white)"} loading={loading} size={80} />
      </div>
      <div className="overlay"></div>
    </>
  );
}
